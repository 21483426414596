<template>
	<div id="landTypeList">
		<page-live-type :is-shop="1"></page-live-type>
	</div>
</template>

<script>
	import pageLiveType  from '@/components/layout/live/page-live-type.vue'
	export default {
		components:{
			pageLiveType
		}
	}
</script>

<style lang="scss">
</style>
